export const staticModeFields = [
  {
    handle: 'speed_over_ground_std',
    label: 'Speed over ground (std)',
    short_label: 'std',
    type: 'speed',
    unit: 'kn',
  },
  {
    handle: 'speed_through_water_std',
    label: 'Speed through ground (std)',
    short_label: 'std',
    type: 'speed',
    unit: 'kn',
  },
  {
    handle: 'duration',
    label: 'Duration',
    short_label: 'Duration',
    type: 'total_duration',
    unit: 'h',
    format: 'dhm',
  },
  {
    handle: 'speed_over_ground_avg',
    label: 'Speed over ground (avg)',
    short_label: 'SOG',
    type: 'speed',
    unit: 'kn',
  },
  {
    handle: 'speed_through_water_avg',
    label: 'Speed through ground (avg)',
    short_label: 'STW',
    type: 'speed',
    unit: 'kn',
  },
  {
    handle: 'shaft_speed_avg',
    label: 'Shaft speed (avg)',
    short_label: 'RPM',
    type: 'engine',
    unit: 'rpm',
  },
  {
    handle: 'shaft_speed_std',
    label: 'Shaft speed (std)',
    short_label: 'std',
    type: 'engine',
    unit: 'rpm',
  },
  {
    handle: 'distance_through_water',
    label: 'Distance through water',
    short_label: 'DTW',
    type: 'distance',
    unit: 'nm',
  },
  {
    handle: 'distance_over_ground',
    label: 'Distance over ground',
    short_label: 'Distance',
    type: 'distance',
    unit: 'nm',
  },
  {
    handle: 'total_consumption',
    label: 'Total consumed',
    short_label: 'Consumed',
    type: 'fuel',
    unit: 't',
  },
  {
    handle: 'consumption_per_day',
    label: 'Daily consumption',
    short_label: 'Cons./d',
    type: 'fuel',
    unit: 't/d',
  },
  {
    handle: 'consumption_per_hour',
    label: 'Hourly consumption',
    short_label: 'Cons./h',
    type: 'fuel',
    unit: 't/h',
  },
  {
    handle: 'consumption_per_nm',
    label: 'Consumption per nautical mile',
    short_label: 'Cons./nm',
    type: 'fuel',
    unit: 'kg/nm',
  },
  {
    handle: 'ae_running_hours',
    label: 'AE Running',
    short_label: 'AE running',
    type: 'running_hours',
    unit: 'h',
    format: 'dhm',
  },
  {
    handle: 'ae_hours_percentage_of_duration',
    label: 'AE Hours %',
    short_label: 'AE hrs %',
    type: 'running_hours_percentage',
    unit: '%',
  },
  {
    handle: 'cii_rating',
    label: 'CII rating',
    short_label: 'CII rating',
    type: 'cii',
    unit: null,
  },
  {
    handle: 'cii_attained',
    label: 'CII attained',
    short_label: 'CII attained',
    type: 'cii',
    unit: 'g/t nm',
  },
];
