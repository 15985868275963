import dchService from '@/services/dch/dchService';
import platformAPIv1 from '@/services/platformAPIv1';
import LocalStorageService from '@/services/localStorageService';
import { staticModeFields } from '@/services/staticData';

const dayjs = require('dayjs');

const state = {
  modes: [],
  modesCursor: '',
  vesselModes: [],
  vesselSummary: {},
  vesselModesCursor: '',
  currentVessel: '', // In use
  modeDetails: {},
  shipsData: [], // Platform
  vesselsData: [],
  vesselsLastVoyage: {}, // New for DcH
  vesselsCiiRatingsLast3Months: {}, // New for DCH
  vesselsYTDData: {}, // New for DCH
  vesselVoyages: [], // New for DCH
  assetsIds: [], // New for DCH
  shipProperties: [], // Platform - Ok
  timeseries: {},
  paths: {},
  newestSeenMode: LocalStorageService.getNewestSeenMode() || '',
  vesselsActiveView: 'list',
  map: {}, // OK
  filteredVessels: LocalStorageService.getUserSelectedVessels(LocalStorageService.getUserEmail()), // Ok
  feedKey: 0,
};

const getters = {
  modes: (state) => {
    return state.modes;
  },
  modesCursor: (state) => {
    return state.modesCursor;
  },
  vesselModes: (state) => {
    return state.vesselModes;
  },
  vesselSummary: (state) => {
    return state.vesselSummary;
  },
  vesselModesCursor: (state) => {
    return state.vesselModesCursor;
  },
  currentVessel: (state) => {
    return state.currentVessel;
  },
  modeDetails: (state) => {
    return state.modeDetails;
  },
  modeFields: (state) => {
    // Returning static data since the aware endpoint was used and only ever returned this
    return staticModeFields;
  },
  shipsData: (state) => {
    return state.shipsData;
  },
  vesselsData: (state) => {
    return state.vesselsData;
  },
  vesselsLastVoyage: (state) => {
    return state.vesselsLastVoyage;
  },
  vesselVoyages: (state) => {
    return state.vesselVoyages;
  },
  vesselsCiiRatingsLast3Months: (state) => {
    return state.vesselsCiiRatingsLast3Months;
  },
  vesselCiiRatingsLast3Months: (state) => (imo) => {
    return state.vesselsCiiRatingsLast3Months[imo];
  },
  vesselsYTDData: (state) => {
    return state.vesselsYTDData;
  },
  vesselYTDData: (state) => (imo) => {
    return state.vesselsYTDData[imo];
  },

  ship: (state, imoNr) => {
    return state.shipsData.find((ship) => ship.imoNr === parseInt(imoNr));
  },
  assetsIds: (state) => {
    return state.assetsIds;
  },
  shipProperties: (state) => {
    return state.shipProperties;
  },
  timeseries: (state) => {
    return state.timeseries;
  },
  paths: (state) => {
    return state.paths;
  },
  newestSeenMode: (state) => {
    return state.newestSeenMode;
  },
  vesselsActiveView: (state) => {
    return state.vesselsActiveView;
  },
  map: (state) => {
    return state.map;
  },
  filteredVessels: (state) => {
    return state.filteredVessels;
  },
  feedKey: (state) => {
    return state.feedKey;
  },
};

const actions = {
  async fetchVesselModesData({ commit, getters, dispatch }, imoNr) {
    let cursor = state.vesselModesCursor || null;
    const resetVessel = imoNr != state.currentVessel;
    if (resetVessel) {
      cursor = null;
    }
    const limit = 10;
    return new Promise((resolve, reject) => {
      dchService
        .getVoyageModes(imoNr, { cursor: cursor, limit_per_source: limit })
        .then((resp) => {
          const [modes, cursor] = resp;
          dispatch('fetchInteractions', {
            ownerObjectsIds: modes.map((m) => m.uuid),
            modeDetails: false,
          });
          commit('setVesselModeData', {
            modes,
            cursor: cursor,
            imoNr,
            shouldAppend: !resetVessel,
          });

          resolve(modes);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async fetchVesselSummaryData({ commit, getters }, { imoNr, modeStart, interval }) {
    return new Promise((resolve, reject) => {
      dchService
        .getSummaryData(imoNr, { interval: interval })
        .then((response) => {
          commit('setVesselSummaryData', {
            summary: response,
            imoNr,
            modeStart,
            interval,
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async fetchModeDetails({ commit, getters }, { imoNr, modeStart }) {
    return new Promise((resolve, reject) => {
      try {
        dchService
          .getVoyageMode(imoNr, modeStart, {})
          .then((response) => {
            //const stateMode = state.modes.find((m) => m.uuid === response.data.mode.uuid);
            // const modeData = response.data.mode;
            if (response && response.length > 0) {
              const mode = response[0];
              commit('setModeDetails', mode);
            }

            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      } catch (e) {
        reject(e);
      }
    });
  },

  //   Using Platform API.
  async fetchShipProperties({ commit }, { imoNr }) {
    return new Promise((resolve, reject) => {
      platformAPIv1
        .get(`ships/${imoNr}/properties`)
        .then((response) => {
          const responseData = response.data.properties.map((r) => (r = { ...r }));
          commit('setShipProperties', responseData);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Get timeseries for given imo, tags, start and end.
   */
  async fetchTimeseries({ commit, getters }, { imo, tags, start, end }) {
    try {
      const today = dayjs.utc();
      end = end ? end : today.endOf('day').format('YYYY-MM-DDTHH:mm:ss');
      const params = {
        start: start,
        end: end,
        asset_ids: imo,
        tags: tags,
      };

      const response = await dchService.getTimeseries(params);
      commit('setTimeseries', {
        imo,
        newTimeseriesData: response.data.data,
        tags: tags.split(','),
      });
    } catch (error) {
      console.error('error fetching DCH timeseris', error);
    }
  },

  /**
   * Get timeseries with positional data for given imo and dates.
   * @param {*} param0
   * @param {*} param1
   */
  async fetchTimeseriesPositions({ commit, getters }, { imo, start, end }) {
    try {
      const today = dayjs.utc();
      end = end ? end : today.endOf('day').format('YYYY-MM-DDTHH:mm:ss');
      const params = {
        start: start,
        end: end,
        asset_ids: imo,
        tags: 'lon,lat',
      };

      const response = await dchService.getTimeseries(params);
      commit('setPositions', {
        imo,
        newTimeseriesData: response.data.data,
      });
    } catch (error) {
      console.error('error fetching DCH timeseries positions', error);
    }
  },
  /**
   * Get Assets from the DCH /assets
   * Filter set of ships/assets from DCH and Platform.
   * @param {*} param0
   */
  async fetchAssets({ commit, getters }) {
    try {
      // Get the assets from the DCH /assets.
      const response = await dchService.getAssets();

      const assets = response.data.data;

      // Only using ships that have reported data in this year. (This will need to change).
      // Maybe this is not neccessary. Because isReporting from the shipProfiles (shipsData) should contain DCH reporting data.
      const dateDropOff = dayjs.utc().startOf('year').format('YYYY-MM-DDTHH:mm:ss');

      // Filter out active assets and map to their asset IDs
      const activeAssets = assets
        .filter(
          (asset) =>
            asset.enabled &&
            asset.active &&
            asset.sources &&
            asset.sources.some(
              (source) =>
                source.type === 'reported' && dayjs.utc(source.last_data_at).isAfter(dateDropOff)
            )
        )
        .map((asset) => asset);

      commit('setShipsData', activeAssets);
      // Might not need this
      commit(
        'setAssetsIds',
        activeAssets.map((asset) => asset.asset_id)
      );
    } catch (error) {
      console.error('Error fetching DCH Assets:', error);
      throw error; // Re-throw the error to be handled by the caller
    }
  },

  /**
   * Fetch last voyages per ship imo from DCH /voyages.
   * Get last voyage by getting the first voyage for today. (Most recent voyage)
   * @param {*} param0
   * @param {*} param1
   * @returns
   */
  async fetchLastVoyages({ commit, getters }, { imosStr }) {
    try {
      const today = dayjs();
      const startOfDay = today.startOf('day').format('YYYY-MM-DDTHH:mm:ss');
      const endOfDay = today.endOf('day').format('YYYY-MM-DDTHH:mm:ss');

      const params = {
        start: startOfDay,
        end: endOfDay,
        interval: 'total',
        report_level: 'operational_data',
        skip_voyage_list: false,
        asset_ids: imosStr,
      };

      const response = await dchService.getVoyages(params);
      const data = response.data.data;
      const voyages = data.filter((ship) => ship.voyages.length > 0).map((ship) => ship.voyages[0]);

      const voyagesByImo = data
        .filter((ship) => ship.voyages.length > 0)
        .reduce((acc, ship) => {
          acc[ship.asset_id] = ship.voyages[0];
          return acc;
        }, {});

      commit('setVesselsLastVoyage', voyagesByImo);

      return voyages;
    } catch (error) {
      console.error('Error fetching DCH voyages:', error);
      throw error; // Re-throw the error to be handled by the caller
    }
  },

  /**
   * Get all vessels emissions. Able to fetch for specified period
   * @param {*} param0
   * @param {*} param1
   */
  async fetchVesselsCiiRatingLast3Months({ commit, getters }, { imosStr }) {
    try {
      const today = dayjs.utc();
      // Last 3 full months
      const start = today.subtract(3, 'months').startOf('month').format('YYYY-MM-DDTHH:mm:ss');
      const end = today.subtract(1, 'month').endOf('month').format('YYYY-MM-DDTHH:mm:ss');

      const params = {
        start: start,
        end: end,
        interval: 'month',
        report_level: 'cii',
        refresh_cache: 'false',
        asset_ids: imosStr,
      };

      const response = await dchService.getEmissions(params);
      const data = response.data.data;

      // Extacting the CII ratings
      function extractCiiRating(vesselsEmissionsData) {
        let ciiRatingsByShip = {};

        vesselsEmissionsData.forEach((vesselEmissionData) => {
          const intervalData = vesselEmissionData.interval_data;
          if (!intervalData) {
            // Handle the case where intervalData is null, undefined, or false
            ciiRatingsByShip[vesselEmissionData.imo] = ['-', '-', '-'];
            return;
          }
          const shipCiiReports = intervalData.map((interval) => {
            if (interval.reports && interval.reports.cii) {
              return interval.reports.cii.cii_rating || '-';
            } else {
              return '-';
            }
          });
          ciiRatingsByShip[vesselEmissionData.imo] = shipCiiReports;
        });

        return ciiRatingsByShip;
      }
      const ciiRatings = extractCiiRating(data);
      commit('setVesselsCiiRatingsLast3Months', ciiRatings);
    } catch (error) {
      console.error('Error fetching DCH voyages:', error);
      throw error; // Re-throw the error to be handled by the caller
    }
  },

  /**
   * Get all vessels emissions. Able to fetch for specified period
   * @param {*} param0
   * @param {*} param1
   */
  async fetchVesselsYTDEmissions({ commit, getters }, { imosStr }) {
    try {
      const today = dayjs.utc();
      const endOfToday = today.endOf('day').format('YYYY-MM-DDTHH:mm:ss');
      const startOfYear = today.startOf('year').format('YYYY-MM-DDTHH:mm:ss');

      const params = {
        start: startOfYear,
        end: endOfToday,
        interval: 'total',
        report_level: 'cii',
        refresh_cache: 'false',
        asset_ids: imosStr,
      };

      const response = await dchService.getEmissions(params);
      const data = response.data.data;

      // Extracting YTD data. Avg sailing speed, total distance and ciiRating.
      // More is available.
      function extractYTDData(vesselsEmissionsData) {
        let ytdByShip = {};
        vesselsEmissionsData.forEach((vesselEmission) => {
          const emptyYTDData = { distance: 0, avg_sailing_speed: 0, ciiRating: '-' };
          const intervalData = vesselEmission.interval_data;
          if (!intervalData) {
            ytdByShip[vesselEmission.imo] = emptyYTDData;
            return;
          }
          const operationalData = intervalData[0].operational_data;

          //   Set default data when there is no operational data.
          if (!operationalData) {
            ytdByShip[vesselEmission.imo] = emptyYTDData;
            return;
          }

          const ytdData = {
            avg_sailing_speed: operationalData.average_sailing_speed || '-',
            distance: operationalData.distance_sailed || '',
            ciiRating: intervalData[0].reports?.cii?.cii_rating || '-',
          };

          ytdByShip[vesselEmission.imo] = ytdData;
          return;
        });
        return ytdByShip;
      }

      const ytdData = extractYTDData(data);
      commit('setVesselsYTDData', ytdData);
    } catch (error) {
      console.error('Error fetching DCH voyages:', error);
      throw error; // Re-throw the error to be handled by the caller
    }
  },
  async fetchActiveShips({ commit, getters }, {}) {},

  changeVesselsView({ commit }, { activeView }) {
    commit('setVesselsView', { activeView });
  },
  setMap({ commit }, { map }) {
    commit('setMap', { map });
  },
  saveFilteredVessels({ commit }, { vessels }) {
    commit('saveFilters', { vessels });
    LocalStorageService.updateSelectedVessels(LocalStorageService.getUserEmail(), vessels);
  },
  incrementFeedKey({ commit }) {
    commit('incFeedKey');
  },
  setVesselSummary({ commit }, { summary, interval }) {
    commit('setVesselSummaryData', { summary, interval });
  },

  // This is used in Aware. IN mode details. Not refactored yet.
  async fetchInteractions({ commit }, { ownerObjectsIds, modeDetails }) {
    return new Promise((resolve, reject) => {
      platformAPIv1
        .post('social/interactions', {
          ownerObjectsIds: ownerObjectsIds,
        })
        .then((response) => {
          const interactions = response.data;
          if (modeDetails) {
            commit('setModeDetailsInteractions', { interactions });
          } else {
            commit('setModeInteractions', { interactions });
            commit('setVesselModeInteractions', { interactions });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async createComment(
    { commit },
    { itemId, comment, ownerObjectType, ownerObjectId, creatorEmail, updated, modeDetail }
  ) {
    let commentObject = {
      itemId: itemId,
      text: comment,
      ownerObjectId: ownerObjectId,
      ownerObjectType: ownerObjectType,
    };
    return new Promise((resolve, reject) => {
      platformAPIv1
        .post('social/comment/create', commentObject)
        .then((response) => {
          const cObject = {
            itemId: itemId,
            text: comment,
            ownerObjectId: ownerObjectId,
            ownerObjectType: ownerObjectType,
            comment: commentObject.text,
            creatorEmail: creatorEmail,
            updated: updated,
          };
          if (modeDetail) {
            commit('setModeDetailComment', { cObject });
          }
          commit('setModeComment', { cObject });
          commit('setVesselModeComment', { cObject });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async createReaction(
    { commit },
    { itemId, reaction, ownerObjectType, ownerObjectId, creatorEmail, updated, modeDetail }
  ) {
    let reactionObject = {
      itemId: itemId,
      reaction: reaction,
      ownerObjectId: ownerObjectId,
      ownerObjectType: ownerObjectType,
    };
    return new Promise((resolve, reject) => {
      platformAPIv1
        .post('social/reaction/create', reactionObject)
        .then((response) => {
          const rObject = {
            itemId: itemId,
            ownerObjectId: ownerObjectId,
            ownerObjectType: ownerObjectType,
            reaction: reaction,
            creatorEmail: creatorEmail,
            updated: updated,
          };
          if (modeDetail) {
            commit('setVesselModeDetailReaction', { rObject });
          }
          commit('setModeReaction', { rObject });
          commit('setVesselModeReaction', { rObject });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async removeReaction(
    { commit },
    { itemId, reaction, ownerObjectType, ownerObjectId, creatorEmail, updated, modeDetail }
  ) {
    return new Promise((resolve, reject) => {
      platformAPIv1
        .delete(`social/reaction/${itemId}`)
        .then((response) => {
          const rObject = {
            itemId: itemId,
            ownerObjectId: ownerObjectId,
            ownerObjectType: ownerObjectType,
            reaction: reaction,
            creatorEmail: creatorEmail,
            updated: updated,
          };
          if (modeDetail) {
            commit('setRemoveVesselModeDetailReaction', { rObject });
          }
          commit('setRemoveModeReaction', { rObject });
          commit('setRemoveVesselModeReaction', { rObject });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  setModeData: (state, { modes, cursor, isPulldownRefresh }) => {
    state.modes = isPulldownRefresh ? modes : [...state.modes, ...modes];
    state.modesCursor = cursor;
  },
  setVesselModeData: (state, { modes, cursor, imoNr, shouldAppend }) => {
    state.vesselModes = shouldAppend ? [...state.vesselModes, ...modes] : modes;
    state.vesselModesCursor = cursor;
    state.currentVessel = imoNr;
  },
  setVesselSummaryData: (state, { summary, imoNr, modeStart, interval }) => {
    state.vesselSummary[interval] = summary;
    if (!modeStart) {
      state.currentVessel = imoNr;
    }
    const index = state.vesselsData.findIndex(
      (mode) => mode.ship_imo === imoNr && mode.start_time === modeStart
    );
    if (index > -1) {
      if (state.vesselsData[index].summary.length === 0) {
        state.vesselsData[index].summary = {};
      }
      state.vesselsData[index].summary[interval] = summary;
    }
  },
  setModeDetails: (state, mode) => {
    state.modeDetails = mode;
  },
  setModeMetrics: (state, { metrics, imoNr, modeStart, dataType }) => {
    const index = state.modes.findIndex(
      (mode) => mode.ship_imo === imoNr && mode.start_time === modeStart
    );
    if (index > -1) {
      state.modes[index].metrics[dataType] = [...state.modes[index].metrics[dataType], ...metrics];
    }
  },
  setShipsData: (state, shipsData) => {
    state.shipsData = shipsData;
  },
  setVesselsData: (state, vesselsData) => {
    state.vesselsData = vesselsData;
  },
  setAssetsIds: (state, assetsIds) => {
    state.assetsIds = assetsIds;
  },
  setVesselsLastVoyage: (state, newVoyages) => {
    // Clone the existing state to avoid direct mutation
    const updatedVoyages = { ...state.vesselsLastVoyage };

    // Iterate over the new voyages and update or add them to the state
    Object.keys(newVoyages).forEach((imo) => {
      updatedVoyages[imo] = newVoyages[imo];
    });

    // Assign the updated voyages object back to the state
    state.vesselsLastVoyage = updatedVoyages;
  },

  setVesselVoyages: (state, vesselVoyages) => {
    state.vesselVoyages = vesselVoyages;
  },
  setVesselsCiiRatingsLast3Months: (state, newCiiRatings) => {
    const updatedCiiRatings = { ...state.vesselsCiiRatingsLast3Months };

    Object.keys(newCiiRatings).forEach((asset_id) => {
      updatedCiiRatings[asset_id] = newCiiRatings[asset_id]; // Update or add new rating
    });

    state.vesselsCiiRatingsLast3Months = updatedCiiRatings;
  },

  setVesselsYTDData: (state, newYtdData) => {
    const updatedYtdData = { ...state.vesselsYTDData };

    Object.keys(newYtdData).forEach((asset_id) => {
      updatedYtdData[asset_id] = newYtdData[asset_id]; // Update or add new data
    });

    state.vesselsYTDData = updatedYtdData;
  },

  setPositions: (state, { imo, newTimeseriesData }) => {
    const assetKey = imo.toString();
    let shipPath = [];
    if (state.paths[imo]) {
      shipPath = [...state.paths[imo]];
    }

    for (const row of newTimeseriesData) {
      shipPath.push({ lat: row.lat, lon: row.lon });
    }
    state.paths[assetKey] = shipPath;
  },

  setTimeseries: (state, { newTimeseriesData, tags }) => {
    const taggedResults = {};
    for (const row of newTimeseriesData) {
      const time = row.time;
      if (!time) {
        continue;
      }
      for (const tag of tags) {
        let value = row[tag];
        if (tag === 'me_cons' && value) {
          let amountTotal = 0;
          for (const fuelData of value) {
            amountTotal += fuelData?.amount;
          }

          value = amountTotal;
        }
        if (!value) {
          continue;
        }
        let datapoint = {};
        datapoint[time] = value;
        if (!taggedResults[tag]) {
          taggedResults[tag] = [datapoint];
        } else {
          taggedResults[tag].push(datapoint);
        }
      }
    }
    state.timeseries = taggedResults;
  },

  setShipProperties: (state, shipProperties) => {
    state.shipProperties = shipProperties;
  },
  setPaths: (state, paths) => {
    state.paths = paths;
  },
  setModeDetailsCii: (state, cii) => {
    state.modeDetails.cii = cii;
  },
  setNewestSeenMode: (state, { modeUuid }) => {
    state.newestSeenMode = modeUuid;
  },
  setModePath: (state, { path, imoNr, modeStart }) => {
    const index = state.vesselsData.findIndex(
      (mode) => mode.ship_imo === imoNr && mode.start_time === modeStart
    );
    if (index > -1) {
      state.vesselsData[index].path = path.position;
    }
  },
  setLastModes: (state, lastModes) => {
    state.modeDetails.lastModes = lastModes;
  },
  setVesselsView: (state, { activeView }) => {
    state.vesselsActiveView = activeView;
  },
  setMap: (state, { map }) => {
    state.map = map;
  },
  saveFilters: (state, { vessels }) => {
    state.filteredVessels = vessels;
  },
  incFeedKey: (state) => {
    state.feedKey += 1;
  },
  setModeInteractions: (state, { interactions }) => {
    state.modes = state.modes.map(
      (m) =>
        (m = {
          ...m,
          comments: interactions.comments.filter((c) => c.ownerObjectId === m.uuid),
          reactions: interactions.reactions.filter((r) => r.ownerObjectId === m.uuid),
        })
    );
  },
  setVesselModeInteractions: (state, { interactions }) => {
    state.vesselModes = state.vesselModes.map(
      (m) =>
        (m = {
          ...m,
          comments: interactions.comments.filter((c) => c.ownerObjectId === m.uuid),
          reactions: interactions.reactions.filter((r) => r.ownerObjectId === m.uuid),
        })
    );
  },
  setModeDetailsInteractions: (state, { interactions }) => {
    state.modeDetails.comments = interactions.comments;
    state.modeDetails.reactions = interactions.reactions;
  },
  setModeComment: (state, { cObject }) => {
    const index = state.modes.findIndex((m) => m.uuid === cObject.ownerObjectId);
    if (index > -1) {
      state.modes[index].comments.push(cObject);
    }
  },
  setVesselModeComment: (state, { cObject }) => {
    const index = state.vesselModes.findIndex((m) => m.uuid === cObject.ownerObjectId);
    if (index > -1) {
      state.vesselModes[index].comments.push(cObject);
    }
  },
  setModeDetailComment: (state, { cObject }) => {
    state.modeDetails.comments.push(cObject);
  },
  setModeReaction: (state, { rObject }) => {
    const index = state.modes.findIndex((m) => m.uuid === rObject.ownerObjectId);
    if (index > -1) {
      state.modes[index].reactions.push(rObject);
    }
  },
  setVesselModeReaction: (state, { rObject }) => {
    const index = state.vesselModes.findIndex((m) => m.uuid === rObject.ownerObjectId);
    if (index > -1) {
      state.vesselModes[index].reactions.push(rObject);
    }
  },
  setVesselModeDetailReaction: (state, { rObject }) => {
    state.modeDetails?.reactions.push(rObject);
  },
  setRemoveModeReaction: (state, { rObject }) => {
    const index = state.modes.findIndex((m) => m.uuid === rObject.ownerObjectId);
    if (index > -1) {
      const itemIndex = state.modes[index].reactions.findIndex((r) => r.itemId === rObject.itemId);
      state.modes[index].reactions.splice(itemIndex, 1);
    }
  },
  setRemoveVesselModeReaction: (state, { rObject }) => {
    const index = state.vesselModes.findIndex((m) => m.uuid === rObject.ownerObjectId);
    if (index > -1) {
      const itemIndex = state.vesselModes[index].reactions.findIndex(
        (r) => r.itemId === rObject.itemId
      );
      state.vesselModes[index].reactions.splice(itemIndex, 1);
    }
  },
  setRemoveVesselModeDetailReaction: (state, { rObject }) => {
    const itemIndex = state.modeDetails?.reactions.findIndex((r) => r.itemId === rObject.itemId);
    if (itemIndex > -1) {
      state.modeDetails.reactions.splice(itemIndex, 1);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
