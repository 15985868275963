import { duration } from 'dayjs';

export function voyageModesToAware(data, included) {
  const modes = [];
  const getPort = getPortFactory(included);
  data.forEach((vessel) => {
    const asset_id = vessel.asset_id;
    vessel.sources.forEach((source) => {
      source.modes.forEach((mode) => {
        modes.push(modeToAware(asset_id, mode, getPort));
      });
    });
  });
  return modes;
}

export function modeToAware(asset_id, mode, getPort) {
  const fromPort = getPort(mode.from_port);
  const toPort = getPort(mode.to_port);
  const awareMode = {
    uuid: mode.mode_id,
    ship_imo: asset_id,
    status: mode.end_time == null ? 'active' : 'completed',
    mode: mapModeType(mode.type),
    mode_label: mapModeLabel(mode.type),
    state: mapModeStatus(mode.type),
    start_time: mode.start_time,
    end_time: mode.end_time,
    locode: fromPort.locode,
    port: fromPort.name,
    toLocode: toPort.locode,
    toPort: toPort.name,
    identifier: tripIdentifier(fromPort.name, toPort.name),
    img: mode.img_url,
    end_timestamp: Math.floor(Date.parse(mode.end_time) / 1000),
    rank: {
      reported: {
        last_data_timestamp: '2024-10-01T14:00:00',
        tags: {
          duration: {
            order: 1,
            average: 14.540909090909093,
            sample_size: 22,
            percentile: 100,
          },
        },
      },
    },
    metrics: {
      reported: [mapModeStartMetric(mode)],
      ais: [],
      autologged: [],
    },
  };

  return awareMode;
}

const mapModeStartMetric = (mode) => {
  const fuels = mode?.operational?.fuel;
  let fuelTotalAmount = 0;
  for (const fuel of fuels) {
    if (fuel?.amount) {
      fuelTotalAmount += fuel?.amount;
    }
  }
  return {
    identifier: 'start',
    label: 'From start',
    duration: mode?.operational?.duration,
    distance_over_ground: mode.operational?.distance,
    total_cons_sum: fuelTotalAmount,
    type: 'from_start',
  };
};
const mapModeType = (modeType) => {
  switch (modeType.toLowerCase()) {
    case 'sea':
      return 'underway';
      break;

    default:
      return 'port';
      break;
  }
};

const mapModeLabel = (modeType) => {
  switch (modeType.toLowerCase()) {
    case 'sea':
      return 'Underway';
      break;

    default:
      return 'Port';
      break;
  }
};

const mapModeStatus = (status) => {
  switch (status.toLowerCase()) {
    case 'sea':
      return 'Sea passage';
      break;
    case 'port':
      return 'Port stay';
      break;
    default:
      return 'Anchored';
      break;
  }
};

function getPortFactory(included) {
  const noop = (locode) => {
    return {
      locode: locode,
      name: locode,
    };
  };
  if (included == null) {
    return noop;
  }
  let portsData = [];
  included.forEach((element) => {
    if ((element.type = 'ports')) {
      if (element.data && element.data.length > 0) {
        portsData = element.data;
      }
      return;
    }
  });

  const locodeToPorts = {};
  portsData.forEach((port) => {
    locodeToPorts[port.locode] = port;
  });
  return (locode) => {
    const portsMap = locodeToPorts;
    const port = portsMap[locode];
    if (port == undefined) {
      return noop(locode);
    }
    return port;
  };
}

function tripIdentifier(from, to) {
  if (!to) {
    return from;
  }
  return `${from} - ${to}`;
}

export const emissionsToAwareSummary = (data) => {
  const emissions = data.data[0];
  if (!emissions || !emissions.interval_data) {
    console.log('missing required data to create summary from dch emissions');
    return null;
  }
  const summary = {
    reported: summaryReports(emissions.ship_imo, emissions.interval_data),
  };

  return summary;
};

const summaryReports = (imo, intervals) => {
  const reports = [];
  for (let i = intervals.length - 1; i >= 0; i--) {
    const report = summaryReport(imo, intervals[i]);
    if (report != null) {
      reports.push(report);
    }
  }
  return reports;
};

const summaryReport = (imo, interval) => {
  if (interval.voyage_count == null || interval.voyage_count == 0) {
    return null;
  }
  const report = {
    uuid: interval.interval_id, // year-month not yearmonth
    ship_imo: imo,
    year: interval.reports.cii.year,
    year_month: interval.interval_id,
  };
  if (!interval.operational_data || !interval.reports || !interval.reports.cii) {
    console.log('missing data to create summary report for interval', report.ship_imo, report.uuid);
    return report;
  }
  report.total = summaryReportTotals(interval.operational_data, interval.reports.cii);
  return report;
};

const summaryReportTotals = (operational, cii) => {
  if (!operational.engine_consumption) {
    console.log('missing consumption data to create summary report totals');
    return {};
  }
  return {
    duration: operational.duration_hours,
    distance_over_ground: operational.distance_sailed,
    me_cons_sum: operational.engine_consumption.main,
    boiler_cons_sum: operational.engine_consumption.boiler,
    ae_cons_sum: operational.engine_consumption.auxiliary,
    other: operational.engine_consumption.other,
    total_cons_sum: operational.engine_consumption.total,
    cons_kg_per_nm_avg: operational.engine_consumption.average_kg_per_nm,
    cons_per_day_avg: operational.engine_consumption.average_mt_per_day,
    cii_attained: cii.cii_attained,
    cii_rating: cii.cii_rating,
    speed_over_ground_avg: operational.average_sailing_speed,
  };
};
