import dayjs from 'dayjs';
import axiosInstance from './dchAPI';
import { voyageModesToAware, emissionsToAwareSummary } from './dchToAware';

// Filter out params based of allowed params.
const filterParms = (params, allowedParams) => {
  return Object.keys(params)
    .filter((key) => allowedParams.includes(key))
    .reduce((obj, key) => {
      obj[key] = params[key];
      return obj;
    }, {});
};

// Define allowed parameters for the voyage API
const allowedVoyagesParams = [
  'start',
  'end',
  'interval',
  'report_level',
  'skip_voyage_list',
  'asset_ids',
];

// All available service routes to the DCH API.
const dchService = {
  // GET voyages
  getVoyages(params) {
    const filteredParams = filterParms(params, allowedVoyagesParams);
    return axiosInstance.get('/voyages', { params: filteredParams });
  },

  // GET timeseries
  getTimeseries(params) {
    return axiosInstance.get('/timeseries', { params });
  },

  // GET emissions
  getEmissions(params) {
    return axiosInstance.get('/emissions', { params });
  },

  // GET assets
  getAssets(params) {
    return axiosInstance.get('/assets', { params });
  },

  // GET aggregations
  getAggregations(params) {
    return axiosInstance.get('/aggregations', { params });
  },

  // GET modes
  async getVoyageModes(asset_id, params) {
    params.asset_ids = asset_id;
    params.source_type = 'reported';
    params.include_ports = true;
    params.order = 'descending';
    params.group_by = 'source';
    try {
      const resp = await axiosInstance.get('/voyages/modes', { params });

      let cursor = null;
      const meta = resp.data.meta;
      if (meta) {
        cursor = meta.cursor;
      }
      return [voyageModesToAware(resp.data.data, resp.data.included), cursor];
    } catch (error) {
      throw error;
    }
  },

  // GET single mode
  async getVoyageMode(asset_id, start, params) {
    params.asset_ids = asset_id;
    params.source_type = 'reported';
    params.include_ports = true;
    params.start = start;
    params.limit_per_source = 1;
    params.group_by = 'source';
    try {
      const resp = await axiosInstance.get('/voyages/modes', { params });

      return voyageModesToAware(resp.data.data, resp.data.included);
    } catch (error) {
      throw error;
    }
  },

  // Returns a summary from Emissions data
  async getSummaryData(asset_id, params) {
    params.asset_ids = asset_id;
    let addToStart = { value: -12, unit: 'month' };
    if (params.interval == 'year') {
      addToStart = { value: -4, unit: 'year' };
    }
    params.start = dayjs
      .utc()
      .add(addToStart.value, addToStart.unit)
      .startOf('month')
      .format('YYYY-MM-DDTHH:mm:ss');
    params.end = dayjs.utc().format('YYYY-MM-DDTHH:mm:ss');
    try {
      const resp = await axiosInstance.get('/emissions', { params });
      return emissionsToAwareSummary(resp.data);
    } catch (error) {
      throw error;
    }
  },
};

export default dchService;
