<template>
  <ion-page id="main-content">
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <!-- Hide the feed. Refactoring needed to implement feed using DCH as source. -->
        <!-- <ion-tab-button
          tab="feed"
          href="/tabs/feed"
          class="tab-button"
          :class="{ 'selected-tab': $route.path === '/tabs/feed' }"
        >
          <ion-icon :icon="apertureOutline" />
          <ion-label>Activity</ion-label>
        </ion-tab-button> -->

        <ion-tab-button
          tab="vessels"
          @click="navToVessels"
          class="tab-button"
          :class="{ 'selected-tab': $route.path === '/tabs/vessels' }"
        >
          <ion-icon :icon="boatOutline" />
          <ion-label>Vessels</ion-label>
        </ion-tab-button>

        <ion-tab-button
          v-if="hasFeature('news')"
          tab="posts"
          href="/tabs/posts"
          class="tab-button posts"
          :class="{ 'selected-tab': $route.path === '/tabs/posts' }"
        >
          <ion-icon :icon="newspaperOutline" />
          <ion-label>News</ion-label>
          <ion-badge v-if="hasUnread()" color="danger">{{ unreadPosts() }}</ion-badge>
        </ion-tab-button>

        <ion-tab-button
          tab="profile"
          href="/tabs/profile"
          class="tab-button"
          :class="{ 'selected-tab': $route.path === '/tabs/profile' }"
        >
          <ion-icon :icon="personCircleOutline" />
          <ion-label>Profile</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
  IonBadge,
} from '@ionic/vue';
import {
  personCircleOutline,
  boatOutline,
  apertureOutline,
  newspaperOutline,
} from 'ionicons/icons';

export default {
  name: 'Tabs',
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
    IonBadge,
  },
  methods: {
    hasFeature(feature) {
      return (
        this.$store.getters.getAwareConfig?.features?.find((f) => f.feature === feature) !==
        undefined
      );
    },
    hasUnread() {
      return this.$store.getters.postData?.filter((p) => !p.read).length > 0;
    },
    unreadPosts() {
      if (this.hasUnread()) {
        const numUnread = this.$store.getters.postData?.filter((p) => !p.read).length;
        return numUnread > 9 ? `9+` : `${numUnread}`;
      }
      return '';
    },
    fetchPosts() {
      return this.$store
        .dispatch('fetchPostsData')
        .then(() => {})
        .catch(() => {});
    },
    navToVessels() {
      this.$router.push({ name: 'vessels' });
    },
  },
  mounted() {
    this.fetchPosts();
  },
  computed: {
    posts() {
      return this.$store.getters.postData;
    },
  },
  setup() {
    return {
      personCircleOutline,
      apertureOutline,
      boatOutline,
      newspaperOutline,
    };
  },
};
</script>

<style scoped>
ion-icon {
  margin: 2px 0 0;
  font-size: 26px;
}
ion-tab-bar {
  display: flex;
}
.tab-button {
  color: var(--ion-color-medium);
}
.selected-tab {
  color: var(--ion-text-color);
}

@media only screen and (min-width: 900px) {
  ion-tab-bar {
    display: none;
  }
}
</style>
